import Vue from 'vue/dist/vue'
import Router from 'vue-router'
import { getUser } from '@/auth.js'
import { AmplifyEventBus } from 'aws-amplify-vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: () => import('@/views/Home.vue'), meta: { requiresAuth: true } },
    { path: '/signIn', name: 'signIn', component: () => import('@/views/Login2.vue'), meta: { requiresAuth: false } },
    // { path: '/signUp', name: 'signUp', component: () => import('@/pages/signUp.vue'), meta: { requiresAuth: false } },
    { path: '*', redirect: { name: 'home' } }
  ]
})
AmplifyEventBus.$on('authState', async (state) => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: () => {
      router.push({ path: '/' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
  console.log(pushPathes)
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  if (!user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    if (to.matched.some((record) => typeof (record.meta.requiresAuth) === 'boolean' && !record.meta.requiresAuth)) {
      return next({
        path: '/'
      })
    }
  }
  return next()
})

export default router
