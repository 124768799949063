import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/mn'
import "element-ui/lib/theme-chalk/index.css";

import VueRouter from 'vue-router'

import Auth from '@aws-amplify/auth'
import awsconfig from './aws-exports'
import router from './router'
import App from './App.vue'
require("./assets/reset.scss");
require("./assets/spacing.scss");
require("./assets/variable.scss");
require("./assets/main.scss");

Auth.configure(awsconfig)

Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(Auth)
Vue.use(VueRouter)

// 
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
